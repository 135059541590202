@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;700;900&family=Poppins:wght@100;300;400;500;700;900&display=swap');

//Fuentes
$fuente_titulos: 'Poppins', sans-serif;
$fuente_base: 'Inter', sans-serif;

//Tamaños Media Queries

$telefono: 48rem;
$tablet: 65rem;
$desktop:102.4rem;

//Colores

$principal: #C299FF;
$secundario: #212529;
$terciario: #292B2E;
$blanco: #ffffff;
$negro: #000000;
$fondo: rgba(158,0,255, .4);
$nav: #1b0042;