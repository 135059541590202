.footer{
    height: 20vh;
    width: 100vw;
    background-color: $terciario;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    @include tablet{
        flex-direction: column;
        height: 100%;
    }
    p{
        color: $blanco;
        font-family: $fuente_titulos;
        font-size: 1.2rem;
        font-weight: 100;
        text-align: justify;
        max-width: 40rem;
        line-height: 2rem;
        letter-spacing: .04rem;
    }
    .socialMedia{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 30rem;
        @include tablet{
            margin-bottom: 2rem;
        }
        img{
            width: 5rem;
        }
    }
}