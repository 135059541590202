.skillsSection{
  height: 100vh;
  width: 100vw;
  background-color: $secundario;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  @include tablet{
    flex-direction: column;
    height: 100%;
    padding: 2rem 0;
  }
  .skillsDescription{
    max-width: 40rem;
    @include tablet{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      max-width: 30rem;
      margin-bottom: 2rem;
    }
    h2{
      font-family: $fuente_titulos;
      font-size: 3.5rem;
      color: $blanco;
      font-weight: 700;
      margin-bottom: 0;
      @include tablet{
        font-size: 2.5rem;
        text-align: center;
      }
    }
    p{
      color: $blanco;
      font-family: $fuente_titulos;
      font-size: 1.2rem;
      font-weight: 100;
      text-align: justify;
      max-width: 40rem;
      line-height: 2rem;
      letter-spacing: .04rem;
    }
  }
  .skillsGrid{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 2rem;
    @include tablet{
      align-content: center;
      justify-items: center;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
    }
  }
}
.skillContainer{
  background-color: $terciario;
  width: 6rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  img{
    width: 4.5rem;
  }
}

.newSkills{
  display: grid;
  align-content: center;
  justify-items: center;
  h3{
    font-family: $fuente_titulos;
    font-size: 2rem;
    color: $blanco;
    font-weight: 300;
  }
  .newSkillsContainer{
    display: flex;
    @include tablet{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-items: center;
      align-content: center;
      gap: 2rem;
    }
    div{
      margin-left: 2rem;
      @include tablet{
        margin: 0;
      }
    }
    div:first-child{
      margin-left: 0;
    }
  }
}