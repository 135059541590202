.header{
    height: 100vh;
    width: 100vw;
    background-color: $secundario;
    display: flex;
    align-items: center;
    justify-content: center;
    @include tablet{
        padding: 4rem 2rem;
    }
    .home{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h2,span,h1,p{
            font-family: $fuente_base;
            color: $blanco;
        }
        span{
            color: $principal;
        }
        h2{
            font-size: 3rem;
            font-weight: 700;
            margin-bottom: 0;
            @include tablet{
                font-size: 1.5rem;
            }
        }
        h1{
            font-size: 4.5rem;
            font-weight: 900;
            margin-bottom: 0;
            @include tablet{
                font-size: 2.5rem;
                text-align: center;
            }
        }
        p{
            font-size: 1.5rem;
            font-weight: 100;
            @include tablet{
                font-size: 1rem;
                text-align: center;
                width: 20rem;
            }
        }
        .imgContainer{
            height: 15rem;
            width: 15rem;
            background-color: $principal;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            @include tablet{
                height: 10rem;
                width: 10rem;
            }
            img{
                width: 14rem;
                @include tablet{
                    width: 9rem;
                }
            }
        }
    }
}