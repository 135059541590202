.worksSection{
    min-height: 100vh;
    width: 100vw;
    background-color: $secundario;
    display: grid;
    justify-items: center;
    align-content: center;
    @include tablet{
        height: 100%;
    }
    h2{
        font-family: $fuente_titulos;
        font-size: 3.5rem;
        font-weight: 700;
        color: $blanco;
        margin-top: 0;
        @include tablet{
            font-size: 2.5rem;
            margin-top: 1rem;
        }
    }
    .worksGrid{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-areas: 
        "work0 work2 work4"
        "work0 work3 work4"
        "work1 work3 work5"
        ;
        gap: 2rem;
        max-width: 100rem;
        @include tablet{
            grid-template-columns: 1fr;
            grid-template-rows: none;
            grid-template-areas:"work0" "work2" "work1" "work3" "work4" "work5";
            width: 100%;
            align-content: center;
            justify-items: center;
            padding: 0 4rem;
        }
        .work{
            border-radius: .8rem;
            min-height: 20rem;
            max-height: 40rem;
            max-width: 50rem;
            background-repeat: no-repeat;
            background-size: cover;
            @include tablet{
                min-height: 0;
                max-width: 30rem;
            }
            .workDescription{
                height: 100%;
                width: 100%;
                background-color: #000000da;
                opacity: 0;
                border-radius: .8rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                @include tablet{
                    min-height: 20rem;
                }
                h3{
                    font-family: $fuente_base;
                    font-size: 2.5rem;
                    color: $blanco;
                    font-weight: 700;
                    margin-top: 0;
                    @include tablet{
                        font-size: 1.5rem;
                        margin: 0;
                    }
                }
                p{
                    font-family: $fuente_base;
                    font-size: 1.3rem;
                    color: $blanco;
                    font-weight: 200;
                    padding: 0 4rem;
                    text-align: center;
                    line-height: 2rem;
                    letter-spacing: .04rem;
                    @include tablet{
                        font-size: .9rem;
                        font-weight: 500;
                        line-height: 1.5rem;
                    }
                }
                .btnContainer{
                    @include tablet{
                        display: none;
                    }
                    button:nth-child(2){
                        margin-left: 2rem;
                    }
                }
            }
            &:hover{
                cursor: pointer;
                .workDescription{
                    .btnContainer{
                        display: flex;
                    }
                    opacity: 1;
                    transition: all .5s ease-in-out;
                }
            }
        }
    }
}
.work0{
    grid-area: work0;
}
.work1{
    grid-area: work1;
}
.work2{
    grid-area: work2;
}
.work3{
    grid-area: work3;
}
.work4{
    grid-area: work4;
}
.work5{
    grid-area: work5;
}