.aboutMeSection{
    height: 100vh;
    width: 100vw;
    background-color: $secundario;
    display: flex;
    align-items: center;
    justify-content: space-around;
    @include tablet{
        flex-direction: column;
        height: 100%;
    }
    .socialMedia{
        display: grid;
        gap: 1rem;
        img{
            width: 4rem;
        }
        @include tablet{
            display: flex;
        }
    }
    img{
        width: 50rem;
        @include tablet{
            width: 30rem;
        }
    }
    .aboutMeContainer{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 50rem;
        @include tablet{
            max-width: 30rem;
            align-items: center;
        }
        h2{
            font-family: $fuente_titulos;
            font-size: 3.5rem;
            font-weight: 700;
            color: $blanco;
            margin-bottom: 0;
            @include tablet{
                font-size: 2.5rem;
            }
        }
        p{
            color: $blanco;
            font-family: $fuente_titulos;
            font-size: 1.2rem;
            font-weight: 100;
            text-align: justify;
            max-width: 40rem;
            line-height: 2rem;
            letter-spacing: .04rem;
        }
    }
}