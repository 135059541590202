.nav {
    background-color: $secundario;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
    height: 6rem;
    padding: 0 2rem;
    @include tablet{
        justify-content: space-between;
    }
    .navLogo{
        img{
            width: 6rem;
            opacity: .7;
            &:hover{
                opacity: 1;
            }
        }
    }
    ul{
        list-style: none;
        display: flex;
        padding: 0;
        @include tablet{
            padding: 0 6rem 6rem 6rem;
        }
        li:last-of-type{
            margin-right: 0;
        }
        li{
            margin-right: 1.5rem;
            @include tablet{
                margin: 0;
            }
        }
        a {
            text-decoration: none;
            color: $blanco;
            font-family: $fuente_titulos;
            font-size: 1.3rem;
            &:hover{
                color: $principal;
            }
        }
    }
    .idiomaContainer{
        width: 7rem;
        display: flex;
        img:first-child{
            margin-right: 1rem;
        }
        img {
            height: 3rem;
            cursor: pointer;
        }
        @include tablet{
            display: none;
        }
    }
    .idiomaContainer.mobile{
        display: none;
        @include tablet{
            display: flex;
        }
    }
}

.navList{
    @include tablet{
        position: absolute;
        top: 100%;
        left: 100%;
        width: 100vw;
        height: 100vh;
        &.mobileActive{
            flex-direction: column;
            align-items: center;
            background-color: $secundario;
            margin: 0;
            justify-content: space-evenly;
            a{
                font-size: 1.7rem;
            }
            animation: posicionar .4s ease-in-out forwards;
            &.desactive{
                animation: reposicionar .4s ease-in-out forwards;
            }
        }
    }
}

@keyframes posicionar{
    0%{
        left: 100%;
    }
    20%{
        left: 80%;
    }
    40%{
        left: 60%;
    }
    60%{
        left: 40%;
    }
    80%{
        left: 20%;
    }
    100%{
        left: 0%;
    }
}
@keyframes reposicionar{
    0%{
        left: 0%;
    }
    20%{
        left: 20%;
    }
    40%{
        left: 40%;
    }
    60%{
        left: 60%;
    }
    80%{
        left: 80%;
    }
    100%{
        left: 100%;
    }
}