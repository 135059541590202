html {
  font-size: 62.5%;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background-color: $secundario;
  overflow-x: hidden;
}

.btn1{
  border: none;
  background-color: $terciario;
  font-family: $fuente_titulos;
  color: $principal;
  text-transform: uppercase;
  font-size: 1.5rem;
  border-radius: 50rem;
  height: 3rem;
  padding: 0 2rem;
  @include tablet{
    font-size: 1.2rem;
    height: 2rem;
  }
}
.btn2{
  border: none;
  background-color: $terciario;
  border-radius: 1rem;
  height: 3rem;
  padding: 0 1.2rem;
 &,a{
    display: flex;
    align-items: center;
    text-decoration: none;
    font-family: $fuente_base;
    color: $blanco;
    font-size: 1.2rem;
  }
  &:hover{
    cursor: pointer;
    background-color: $principal;
    color: $negro;
  }
}