//Media Queries
@mixin telefono {
    @media (min-width: #{$telefono}){
        @content;
    }
}
@mixin tablet {
    @media (max-width: #{$tablet}){
        @content;
    }
}
@mixin desktop {
    @media (min-width: #{$desktop}){
        @content;
    }
}
@mixin grid ($columnas, $espaciado){
    display: grid;
    gap: $espaciado;
    grid-template-columns: repeat($columnas, 1fr);
}